import React from "react";
import './Start.scss';
import { nanoid } from 'nanoid'
import { useNavigate, Link } from "react-router-dom";

export default function StartPage() {
    const navigate = useNavigate();

    const newGame = () => {
        const newSaveId = nanoid();
        console.log(newSaveId);
        navigate('/initiative/' + newSaveId);
    }

    return (
        <React.Fragment>
            <div className="TopBar">
                <div id="LeftActions">
                </div>
                <div id="RightActions">
                    {localStorage.getItem('latestSave') && localStorage.getItem('save-' + localStorage.getItem('latestSave')) &&
                        (<a href={`${process.env.PUBLIC_URL}/initiative/${localStorage.getItem('latestSave')}`} className="FancyButton">Continue your last Scene <span className="fa-solid fa-play"/></a>)
                    }
                </div>
            </div>
            <div id='Start'>
                <div style={{textAlign: 'center'}}>
                    <img style={{maxWidth: '400px'}} src={`${process.env.PUBLIC_URL}/battletrack.png`}/>
                    <h2>The only tool you need to track your TTRPG turn based Scenes</h2>
                </div>
                <div className="ActionArea">
                    <Link to="/load" className="HugeButton">Load Scene</Link>
                    <button onClick={newGame} className="HugeButton">New Scene</button>
                </div>
                <div className="Features">
                    <div className="Feature">
                        <h1>System agnostic</h1>
                        <ul>
                            <li>Usable with any 5e, 3.5e compatible system and even lite games</li>
                            <li>Most common abstractions instead of brand specific stats</li>
                            <li>Freeform text for conditions and status</li>
                        </ul>
                    </div>
                    <div className="Feature">
                        <h1>Simple Interface</h1>
                        <ul>
                            <li>All the extras are optional; You can just use the app to track turns and hit points</li>
                            <li>Reminders and counters to free your mind from the boring and focus on the action</li>
                            <li>Dice roller built in if you forgot your set at home</li>
                        </ul>
                    </div>
                    <div className="Feature">
                        <h1>Quick and easy game turns</h1>
                        <ul>
                            <li>Share a live link to your players so they know who's next and prepare (Coming soon)</li>
                            <li>Eliminate handwritting stats and condition tracking</li>
                            <li>Stop wasting time on calculating healing points and half-rounded-up damage</li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}
