import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Track.scss';

export default function Track (props) {
    const { key } = useParams();

    const [data, setData] = useState();
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = async () => {
        if (fetching) { return; } // prevent double fetching at the same time
        setFetching(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/getdata/${key}`);
            const responseData = await response.json();
            setFetching(false);
            setData(responseData.data);
        } catch (e) {
            setFetching(false);
            setError(true);
            clearInterval(intervalRef.current);
        }
    }

    useEffect(() => {
        if (key) {
            fetchData();
        }
    }, [key])


    const intervalRef = useRef();
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            fetchData();
        }, 4000);
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [])

    return (
        <React.Fragment>
            <div className="TopBar">
                <div id="LeftActions">
                </div>
                <div id="Rounds">
                    <span className='RoundNumber'>{data ? data.round : 0}</span>
                </div>
                <div id="RightActions">
                    {data && (
                        <span>
                        <span className='fa-solid fa-clock' />{' '}
                        {String(Math.floor((data.round - 1) * 6 / 60).toFixed(0)).padStart(2, '0')}
                        :
                        {String((data.round - 1) * 6 % 60).padStart(2, '0')}
                    </span>
                    )}
                </div>
            </div>
            <p>Round: {data && data.round}</p>
            <div className='TrackArea'>
                {error && 
                    <div>
                        <p>Cannot connect to session, please try again in a moment</p>
                        <img src={`${process.env.PUBLIC_URL}/nat1.svg`}/>
                    </div>}
                {data && data.characters.map(character => (
                    <div className={`CharacterTrackPill ${data.activeId && (character.id === data.activeId) ? 'Active' : ''}`}>
                        <span>{character.name}</span>
                    </div>
                ))}
            </div>
        </React.Fragment>

    );
}