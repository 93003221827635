import React, {useState, useMemo} from 'react';
import Modal from 'react-modal';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function (props) {
    const [copied, setCopied] = useState(false);

    const url = useMemo(() => {
        return `${process.env.PUBLIC_URL}/player/${props.shareKey}`
    }, [props.shareKey])

    return (
        <Modal className='CharacterModal' isOpen={props.open} onRequestClose={props.onClose}>
            {props.shareKey && (
                <React.Fragment>
                    <p>Share with your players</p>
                    <input type='text' value={url} readOnly/>
                    <CopyToClipboard onCopy={() => setCopied(true)} text={url}><button className='Confirm'>Copy</button></CopyToClipboard>
                    <br/>
                    {copied && <span style={{color: 'green'}}>Copied URL to clipboard <span className='fa-solid fa-check'/></span>}
                    <div className='ActionArea'>
                        <button onClick={props.onClose}>Close</button>
                    </div>
                </React.Fragment>
            )}
            {props.shareError && (
                <React.Fragment>
                    <p>Failed to share session, please try again later</p>
                    <div style={{display: 'block'}} className='ActionArea'>
                        <button onClick={props.onClose}>Close</button>
                    </div>
                </React.Fragment>
            )}
            {!props.shareError && !props.shareKey && (
                <p>Loading...</p>
            )}

        </Modal>
    )
}

