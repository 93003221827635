export const singleRoll = (sides) => {
    return Math.floor(Math.random() * sides + 1);  // 0 - 1 * 20 => 0 - 20
}

export const multiRoll = (diceAmount, sides) => {
    return [...new Array(diceAmount)].map(() => singleRoll(sides));
}

export const playRollSound = () => {
    const diceAudio = new Audio(`${process.env.PUBLIC_URL}/dice.mp3`);
    diceAudio.play();
}