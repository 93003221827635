import React, {useState, useMemo } from 'react';
import Modal from 'react-modal';
import { singleRoll, playRollSound } from './lib/DiceUtil';
import './DiceRoller.scss';


const FormatResult = (props) => {
    if (props.result === parseInt(props.die) || props.result === 1) {
        return <strong>{props.result} </strong>
    } else {
        return <span>{props.result} </span>
    }
}

export default function DiceRoller (props) {
    const [diceAmount, setDiceAmount] = useState({
        '20': 0,
        '12': 0,
        '10': 0,
        '8': 0,
        '6': 0,
        '4': 0
    });

    const [diceResults, setDiceResults] = useState(null);


    const roll = () => {
        const results = {
            '20': [],
            '12': [],
            '10': [],
            '8': [],
            '6': [],
            '4': []
        }
        let atLeastOneRolled = false;
        const dice = Object.keys(diceAmount);
        for (const die of dice) {
            for (let i = 0; i < diceAmount[die]; i++) {
                results[die].push(singleRoll(die));
                atLeastOneRolled = true;
            }
        }
        if (atLeastOneRolled) {
            setDiceResults(results);
            playRollSound();
        }
    }

    const clear = () => {
        setDiceAmount({
            '20': 0,
            '12': 0,
            '10': 0,
            '8': 0,
            '6': 0,
            '4': 0
        });
        setDiceResults(null);
    }

    const increment = (die) => {
        setDiceAmount({...diceAmount, [die]: diceAmount[die] + 1})
    }

    const decrement = (die) => {
        if (diceAmount[die] === 0) { return ; }
        setDiceAmount({...diceAmount, [die]: diceAmount[die] - 1})
    }

    const sumResults = useMemo(() => {
        if (!diceResults) {
            return 0;
        }
        let sum = 0;
        const dice = Object.keys(diceResults);
        for (const die of dice) {
            for (const res of diceResults[die]) {
                sum += res;
            }
        }
        return sum;
    }, [diceResults])

    return (
        <Modal className='CharacterModal' isOpen={props.open} onRequestClose={props.onClose}>
            <div id='DiceRoller'>
                <table>
                    <tr>
                        {['20','12','10','8','6','4'].map(die => (
                            <th>{diceAmount[die]} x D{die}<br/><button onClick={() => decrement(die)}>-</button><button onClick={() => increment(die)}>+</button></th>
                        ))}
                    </tr>
                    {diceResults &&
                    <tr>
                        {['20','12','10','8','6','4'].map(die => (
                            <td style={{maxWidth: '70px'}}>{diceResults[die].map(result => <FormatResult die={die} result={result}/>)}</td>
                        ))}
                    </tr>
                    }
                </table>
                Total: {sumResults}<br/>
                <span style={{color:'#888'}}>(Halved: {Math.floor(sumResults / 2)})</span>
            </div>
            <div className='ActionArea'>
                <button onClick={clear} type='button' className='Fancy'>Clear</button>
                <button onClick={roll} type='submit' className='Confirm Fancy'>Roll</button>
            </div>
        </Modal>
    )
}

