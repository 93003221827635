import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import './GlobalMenu.scss'

export default function GlobalMenu (props) {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
        setOpen(!open);
    }

    const resetFunction = () => {
        if (window.confirm('Resetting will DELETE all characters. Are you sure?')) {
            props.handleReset();
        }
        setOpen(false);
    }

    const loadTestFunction = () => {
        if (window.confirm('Loading test characters will REPLACE all characters. Are you sure?')) {
            props.handleLoadTest();
        }
        setOpen(false);
    }

    const storeData = () => {
        setOpen(false);
        props.handleStoreData();
    }

    return (
        <div id='GlobalMenu'>
            <span onClick={toggleOpen} className='icon'><span className='fa-solid fa-cog'/></span>
            {open && (
                <div className='Content'>
                    <li><Link to='/'>Home</Link><span className='fa-solid fa-home'/></li>
                    <li onClick={resetFunction}>Reset <span className='fa-solid fa-rotate-right'/></li>
                    <li onClick={loadTestFunction}>Load Test Characters <span className="fa-solid fa-vials"/></li>
                    {process.env.REACT_APP_SHARE === 'on' && (<li onClick={storeData}>Share Session with players <span className='fa-solid fa-share-nodes'/></li>)}
                </div>
            )}
        </div>
    )
}