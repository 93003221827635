
import { useEditor, EditorContent, FloatingMenu, BubbleMenu, Mark, textInputRule, markInputRule, mergeAttributes, markPasteRule } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useEffect, useState } from 'react'
import './Statblock.scss';
import { multiRoll, playRollSound } from '../lib/DiceUtil';
import { Plugin } from '@tiptap/pm/state'
import { Decoration, DecorationSet } from '@tiptap/pm/view'
import { Extension } from '@tiptap/core'


const diceFormulaExpression = /(?<Dice>\d+d\d+)*(?<Modifier>\s*([+-])\s*\d+)*/gi;

const findRolls = (doc) => {
  const hexColor = diceFormulaExpression
  const decorations = []

  doc.descendants((node, position) => {
    if (!node.text) {
      return
    }

    Array.from(node.text.matchAll(hexColor)).forEach(match => {
      const color = 'red';
      const index = match.index || 0
      const from = position + index
      // const to = from + color.length
      const to = position + index + match[0].length;
      const decoration = Decoration.inline(from, to, {
        class: 'rollHighlight',
        // style: `--color: ${color}`,
      })

      decorations.push(decoration)
    })
  })

  return DecorationSet.create(doc, decorations)
}

const RollHighlighter = Extension.create({
  name: 'rollHighlighter',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        state: {
          init(_, { doc }) {
            return findRolls(doc)
          },
          apply(transaction, oldState) {
            return transaction.docChanged ? findRolls(transaction.doc) : oldState
          },
        },
        props: {
          decorations(state) {
            return this.getState(state)
          },
        },
      }),
    ]
  },
})

// define your extension array
const extensions = [
  StarterKit,
  RollHighlighter
]

const content = '<p><mark>Hello World!</mark></p><p><strong>Greatsword</strong>+10 to hit, 2d6 + 4 sls</p>';

const RollResult = (props) => {

  const sum = () => {
    const fromDice = props.got.reduce((acc, curr) => acc+curr);
    if (props.mod) {
      return eval(`${fromDice}${props.mod}`);
    } else {
      return fromDice;
    }
  }
  return (
    <p>Rolled <span style={{color: 'green'}}>{props.rolled.replace(' ', '')}</span>
    <span style={{color: 'blue'}}>{props.mod? props.mod.replace(' ', '') : ''}</span>: <span style={{color: 'green'}}>{props.got.join("+")}</span>
    <span style={{color: 'blue'}}>{props.mod}</span> = {sum()} </p>
  )
}

const Statblock = (props) => {
  const [rollExpression, setRollExpression] = useState('');
  const [rollResults, setRollResults] = useState([]);

  const editor = useEditor({
    extensions,
    content: props.value,
    onUpdate: ({ editor }) => {
      props.onUpdate(editor.getHTML());
    },
  })

  const roll = () => {
    const rollExpressionPattern = new RegExp(/(\d.*d\d*)?\s*([+-]\s*\d*)?/, 'g');
    const rollGroups = [...rollExpression.matchAll(rollExpressionPattern)];
    const diceExpression = rollGroups[0][1];
    const modifierExpression = rollGroups[0][2];
    playRollSound();
    const diceExpressionGroups = [...diceExpression.matchAll(new RegExp(/(\d.*)d(\d*)/, 'g'))];
    try {
      const diceResults = multiRoll(parseInt(diceExpressionGroups[0][1]), parseInt(diceExpressionGroups[0][2]));
      setRollResults([...rollResults, {rolled:diceExpressionGroups[0][0] , got: diceResults, mod: modifierExpression}]);
    } catch (e) {
      console.error('Invalid dice parameters');
    }
  }

  const getDiceExpression = (text) => {
    const dicePattern = new RegExp(diceFormulaExpression);
    const parsedExpr = [...text.matchAll(dicePattern)];
    let dice = parsedExpr[0].groups['Dice'];
    const modifier = parsedExpr[0].groups['Modifier'];
    if (!dice && !modifier) {
      return '';
    }
    if (!dice) {
      dice = '1d20'
    }
    return `${dice}${modifier}`;
  }

  const bubbleShow = (bubbleProps) => {
    const {editor, view, state, oldState, from, to} = bubbleProps;
    const fullText = editor.getText();
    const matchesLocations = [];
    try {
      Array.from(fullText.matchAll(diceFormulaExpression)).forEach(match => {
        const index = match.index || 0
        const from = index
        // const to = from + color.length
        const to = index + match[0].length + 1;
        matchesLocations.push({from, to});
      })
    } catch (e) {
      console.log(e);
    }

    // console.log("BUBBLE SHOW", from, to, editor.getText(), "----", editor.getText().substr(from - 1, to - from));
    for (const location of matchesLocations) {
      if (location.from < from && location.to > to) {
        editor.commands.setTextSelection({ from: location.from + 1, to: location.to })
      }
    }
    if (from === to) {
      return false;
    }
    setRollExpression(getDiceExpression(state.doc.textBetween(from, to, '')));
    return true;
  }

  return (
    <div className='Statblock'>
      <div className='Editor'>
        <h1>Statblock</h1>
        <EditorContent editor={editor} />
        <BubbleMenu editor={editor} shouldShow={bubbleShow}><button onClick={roll} disabled={!rollExpression}>Roll {rollExpression}</button></BubbleMenu>
      </div>
      <div className='Log'>
        <h1>Dice Results</h1>
        {rollResults.map(result => (
          <RollResult rolled={result.rolled} got={result.got} mod={result.mod}/>
        ))}
      </div>
    </div>
  )
}

export default Statblock