import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import App from './App.js';
import StartPage from "./StartPage.js";
import Track from './Track.js';
import LoadGame from "./LoadGame.js";

export default function AppRouter () {
    return (
        <BrowserRouter basename={`${process.env.REACT_APP_BASEURL}`}>
            <Routes>
                <Route path='/player/:key' element={<Track/>}/>
                <Route path='/' element={<StartPage/>}/>
                <Route path='/initiative/:saveid' element={<App/>}/>
                <Route path='/load' element={<LoadGame/>}/>
            </Routes>
        </BrowserRouter>
    )
}