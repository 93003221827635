import React, {useState, useMemo } from 'react';
import Modal from 'react-modal';
import './History.scss';
import ReactMarkdown from 'react-markdown'

const renderHistory = (historyData) => {
    if (historyData.type === "harm") {
        return `**${historyData.params.target}** got hurt for ${historyData.params.value}`
    } else if (historyData.type === "heal") {
        return `**${historyData.params.target}** got healed for ${historyData.params.value}`
    }
}

export default function History (props) {
    return (
        <Modal className='CharacterModal' isOpen={props.open} onRequestClose={props.onClose}>
            <div id='History'>
                {props.history.filter(history => history.type).length === 0 ? 'History is empty'
                : <button className='FancyButton' type='button' onClick={props.clearHistory}>Clear History</button>}
                <div className='HistoryArea'>
                    {props.history.filter(history => history.type).map(entry => (
                        <React.Fragment>
                            <ReactMarkdown components={{ p: "span" }}>{renderHistory(entry)}</ReactMarkdown>
                            {' '}<span className='time'>{new Date(entry.time).toLocaleString()}</span>
                            <br/>
                        </React.Fragment>
                    ))}
                </div>

            </div>
        </Modal>
    )
}